import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import {
  getItems,
  addNewItem,
  updateItem,
  getItemById,
  updateSupplier,
  deleteItem,
  clearItemError,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { getItemTypeOptions, hasAccess, timestampFileName, toastMessage } from "helpers/utils"
import ItemTable from "containers/item/ItemTable";
import ModalForm from "containers/item/ModalForm";
import { debounce } from "lodash";
import { getItemToExcel, getItemUploadTemplate } from "store/item/saga"
import UploadModalForm from "containers/item/UploadModalForm";
import Select from "react-select"
import { categoryService } from "../../services/category-service"
import ItemPrintPreviewModal from "./ItemPrintPreviewModal"
import { permission } from "../../constants/permission"
import LocationSelection from "../../containers/location/LocationSelection";

const ItemList = props => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [moreActionIsOpen, setMoreActionIsOpen] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [ type, setType ] = useState(null);
  const [ locations, setLocations ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ categoryOptions, setCategoryOptions ] = useState([]);
  const [ toggleSort, setToggleSort ] = useState(false);
  const [ sortField, setSortField ] = useState('Code')
  const [ sort, setSort ] = useState(null);
  const [ modalPrintPreview, setModalPrintPreview ] = useState()

  const {
    getItems,
    addNewItem,
    deleteItem,
    updateItem,
    clearItemError,
    getItemById,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
    history,
  } = props;

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 1000),
    []
  );

  useEffect(() => {
    getItems({ 
      page, 
      pageSize, 
      term, 
      type: type?.value ?? null, 
      locationIds: locations.map(a => {
        return a.value
      }),
      categoryIds: categories?.map(a =>{
        return a.value
      }),
      
      sortBy: sortField,
      sortAsc: toggleSort,
    });
  }, [page, pageSize, term, type, locations, categories, sortField, toggleSort]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearItemError();
    }
  }, [message]);

  useEffect(() => {
    categoryService.getRequest().then(data => {
      if (data) {
        setCategoryOptions(data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.name,
              }
            }),
        )
      }
    })
    
  }, []);
  
  const handleOnSort=({sortField, asc})=>{
    setToggleSort(!toggleSort);
    setSortField(sortField);
  }

  const handleOnEdit = id => {
    getItemById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = supplier => {
    updateItem(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = item => {
    addNewItem(item);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteItem(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleDetail = id => {
    history.push(`/item/${id}`);
  };

  const handleSubmitUpload = () => {
    setModalUpload(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Item | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Item" breadcrumbItem="Item List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={e => {
                              debouncedFetchData(e.target.value);
                            }}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <Select
                          name="type"
                          value={ type }
                          onChange={ option => {
                            setType(option)
                          } }
                          options={ getItemTypeOptions() }
                          classNamePrefix="select2-selection"
                          placeholder={"Filter by Type"}
                          isClearable
                      />
                    </Col>
                    <Col md={2}>
                      <LocationSelection 
                          name={"locations"} 
                          isMulti={true}
                          value={locations} 
                          onChange={setLocations}
                          placeholder={"Filter by Locations"}
                      />
                    </Col>
                    <Col md={2}>
                      <Select
                          name="categories"
                          value={ categories }
                          onChange={ option => {
                            setCategories(option)
                          } }
                          options={ categoryOptions }
                          classNamePrefix="select2-selection"
                          placeholder={"Filter by Category"}
                          isClearable
                          isMulti
                      />
                    </Col>
                    <Col md={4}>
                      {/* <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div> */}
                      <div className="text-sm-end">
                        { hasAccess(permission.item.write) &&
                            <Button
                                type="button"
                                color="primary"
                                className="me-1"
                                onClick={ handleOnAddNew }
                            >
                              <i className="fas fa-plus"/> Add New
                            </Button>
                        }

                        <ButtonDropdown
                          isOpen={moreActionIsOpen}
                          toggle={() => setMoreActionIsOpen(!moreActionIsOpen)}
                        >
                          <DropdownToggle caret color="primary" outline>
                            <i className="mdi mdi-dots-vertical"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              onClick={() => {
                                getItemUploadTemplate(
                                  "item-upload-template.xlsx"
                                ).finally(()=>{});
                              }}
                              className="text-primary"
                            >
                              <i className="fas fa-file-download me-1" />{" "}
                              Download Import Template
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={() => setModalUpload(true)}
                                className="text-primary"
                            >
                              <i className="fas fa-file-import me-1" /> Import
                              From Excel
                            </DropdownItem>
                            <DropdownItem
                                onClick={()=>{
                                  getItemToExcel(
                                      `${timestampFileName()}_Accessories.xlsx`
                                  );
                                }}
                              className="text-primary"
                            >
                              <i className="fas fa-file-export me-1" /> Export
                              to Excel
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={()=>{
                                  setModalPrintPreview(true);
                                }}
                                className="text-primary"
                            >
                              <i className="fas fa-file-pdf me-1" /> Export
                              to PDF
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <ItemTable
                  items={items}
                  itemCount={itemCount}
                  page={page}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onDetails={handleDetail}
                  onEdit={handleOnEdit}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={page => setPage(page)}
                  onPageSizeChange={pageSize => {
                    setPage(1);
                    setPageSize(pageSize);
                  }}
                  toggleSort={toggleSort}
                  sortField={sortField}
                  onSort={handleOnSort}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit Item"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add Item"}
                isOpen={modalAddIsOpen}
                toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={() =>
                  setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                }
                onSubmit={handleDelete}
              />
              <UploadModalForm
                title="Import Items"
                isOpen={modalUpload}
                toggle={() => setModalUpload(!modalUpload)}
                onSubmit={handleSubmitUpload}
              />
              <ItemPrintPreviewModal
                  title={ "Modal" }
                  isOpen={ modalPrintPreview }
                  toggle={ () => setModalPrintPreview(false) }
                  term={ term }
                  type={ type }
                  locations={ locations }
                  categories={ categories }
                  toggleSort={toggleSort}
                  sortField={sortField}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ItemList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object,

  getItems: PropTypes.func,
  addNewItem: PropTypes.func,
  updateItem: PropTypes.func,
  getItemById: PropTypes.func,
  deleteItem: PropTypes.func,
  clearItemError: PropTypes.func,
};

const mapStateToProps = ({ item }) => {
  return item;
};

export default withRouter(
  connect(mapStateToProps, {
    getItems,
    addNewItem,
    updateItem,
    getItemById,
    deleteItem,
    updateSupplier,
    clearItemError,
  })(ItemList)
);
