import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from "react-select"
import { Link } from "react-router-dom"
import NumberFormat from "react-number-format"
import ModalForm from "../item-unit-of-measurement/ModalForm"
import { NIL } from "uuid"
import {
  addNewItemUnitOfMeasurementRequest, 
  getItemUnitOfMeasurementByIdRequest,
} from "../../store/item-unit-of-measurement/saga"


const StockInItemRow = props => {
  const { item, onChange, onDelete, isSubmitted } = props

  const [ isEditable, setIsEditable ] = useState(true)
  const [ modalAddUom, setModalAddUom ] = useState(false)
  const [ itemUom, setItemUom ] = useState({
    id: NIL,
    title: '',
    itemId: item.itemId?.value,
    unitOfMeasurementId: null,
    displayOrder: 0,
    isBase: false,
    onHandQuantity: 0,
    outgoingQuantity: 0,
    minQuantity: 0,
    conversionRate: 1,
    note: '',
  })

  const handleCheckboxChange = (e) => {
    const { checked } = e.target
    onChange({
      ...item,
      isSelected: checked
    })
  }
  
  const handleSelectChange = (valueType, actionMeta) => {
    const { name, action } = actionMeta
    onChange({
      ...item,
      [name]: valueType
    })
  }

  const handleSubmit = (itemUom) => {
    addNewItemUnitOfMeasurementRequest(itemUom).then((id) => {
      getItemUnitOfMeasurementByIdRequest(id).then(data =>{
        let option = {
          id: data.id,
          value: data.unitOfMeasurementId,
          label: `${data.title} - ${data.locationName}`
        };
        
        onChange({
          ...item,
          unitOfMeasurementId: option,
          uomOptions: item.uomOptions.concat(option)
        });
        setModalAddUom(false);
      });
      
    })
  }

  return (
      <>
        <tr onDoubleClick={ () => setIsEditable(!isEditable) }>
          <td className={ "input text-center pt-2" }>
            <input
                type="checkbox"
                className="form-check-input"
                checked={ item.isSelected }
                readOnly={ true }
                onChange={ handleCheckboxChange }
            />
          </td>
          <td>
            <>{ item.label }</>
          </td>
          <td className={ isEditable ? 'input' : 'text-sm-end' }>
            {
              isEditable ?
                  <>
                    <NumberFormat
                        name="quantity"
                        value={ item.quantity }
                        className={
                            "form-control text-end " +
                            (isSubmitted && item.quantity === 0 ? "is-invalid" : "")
                        }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          onChange({
                            ...item,
                            quantity: values.floatValue
                          })
                        } }
                    />
                  </>
                  :
                  <div className={ isSubmitted && item.quantity === 0 ? ' is-invalid' : '' }>{ item.quantity }</div>
            }
            { isSubmitted && item.quantity === 0 && (
                <div className="invalid-feedback-custom">
                  Quantity is required.
                </div>
            ) }
          </td>
          <td className={ isEditable ? 'input' : '' }>
            {
              isEditable ?
                  <Select
                      name="unitOfMeasurementId"
                      value={ item.unitOfMeasurementId }
                      onChange={ handleSelectChange }
                      className={ isSubmitted && !item.unitOfMeasurementId ? "is-invalid" : '' }
                      options={ item.uomOptions }
                      classNamePrefix="select2-selection"
                      isClearable
                  /> : <div className={ isSubmitted && !item.unitOfMeasurementId ? "is-invalid" : '' }>
                    { item.unitOfMeasurementId?.label }</div>
            }

            { isSubmitted && !item.unitOfMeasurementId &&
                <div className="invalid-feedback-custom">
                  Unit of Measurement is required.
                </div>
            }

          </td>
          <td className={ "input text-center" }>
            <div className="d-flex gap-3 justify-content-center">
              <Link className="text-primary" to="#">
                <i
                    className={ "font-size-18 mdi " + (isEditable ? 'mdi-eye' : 'mdi-pencil') }
                    id="edittooltip"
                    onClick={ () => {
                      setIsEditable(!isEditable)
                    } }
                ></i>
              </Link>

              <Link className="text-danger" to="#">
                <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={ () => {
                      onDelete(item)
                    } }
                ></i>
              </Link>
            </div>
          </td>
        </tr>
        <ModalForm
            title="Add Unit of Measurement"
            item={ itemUom }
            isOpen={ modalAddUom }
            toggle={ () => setModalAddUom(!modalAddUom) }
            onSubmit={ handleSubmit }
        />
      </>
  )
}

StockInItemRow.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isSubmitted: PropTypes.bool
}

export default StockInItemRow