import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, DropdownItem, DropdownMenu, DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown
} from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { customSingleValue, formatItemOptionLabel, getMaxValue } from "../../helpers/utils"
import { getItemsRequest } from "../../store/item/saga"
import { debounce } from "lodash"
import { getItemUnitOfMeasurementsRequest } from "../../store/item-unit-of-measurement/saga"
import StockInItemRow from "./StockInItemRow"
import moment from "moment/moment"
import StockInConfirmModal from "./StockInConfirmModal"
import PrintPreviewModal from "./PrintPreviewModal"

const StockInForm = props => {
  const { item, onSubmit, onCancel } = props

  const [ term, setTerm ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ itemOptions, setItemOptions ] = useState([])
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ modalPrintPreview, setModalPrintPreview ] = useState(false)

  const [ stockIn, setStockIn ] = useState({
    id: NIL,
    code: '',
    name: '',
    status: 0,
    date: new Date(),
    stockInItems: []
  })

  const [ isSubmitted, setIsSubmitted ] = useState(false)

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  useEffect(() => {
    if (item) {
      setStockIn({
        ...item,
        date: moment(item.date).toDate(),
        stockInItems: item.stockInItems.map((a, index) => {
          return {
            ...a,
            index,
            label: `${ a.itemCode } - ${ a.itemName }`,
            itemId: {
              key: a.itemId,
              value: a.itemId,
            },
            unitOfMeasurementId: {
              id: a.itemUnitOfMeasurementId,
              value: a.unitOfMeasurementId,
              locationId: a.locationId,
              label: `${a.itemUnitOfMeasurementTitle} - ${a.locationName}`
            }
          }
        })
      })

    } else {
      setStockIn({
        id: NIL,
        code: '',
        name: '',
        date: new Date(),
        status: 0,
        stockInItems: []
      })
    }
  }, [ item ])

  useEffect(() => {
    setLoading(true)
    getItemsRequest({ term, page: 1, pageSize: 20 }).then(res => {
      if (res.data) {
        setLoading(false)
        setItemOptions(res.data.map((a, index) => {
          return {
            key: a.id,
            value: a.id,
            type: a.type,
            itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
            quantity: a.onHandQuantity,
            label: `${ a.code } - ${ a.name }`
          }
        }))
      }
    })
  }, [ term ])

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    switch (name) {
      case 'itemId':
        if (valueType) {
          const { value, label } = valueType
          getItemUnitOfMeasurementsRequest({ itemId: value, pageSize: 100 }).then(res => {
            const { data } = res
            let uomOptions = data?.map(a => {
              return {
                id: a.id,
                value: a.unitOfMeasurementId,
                locationId: a.locationId,
                label: `${a.title} - ${a.locationName}`
              }
            }) ?? []

            let max = 0
            if (stockIn.stockInItems.length > 0) {
              max = getMaxValue(
                  stockIn.stockInItems.map(a => {
                    return a.index
                  })
              )
              max += 1
            } else {
              max = max + 1
            }

            let newEntry = {
              index: max,
              id: NIL,
              itemId: valueType,
              label: label,
              quantity: 1,
              itemUnitOfMeasurementId: null,
              unitOfMeasurementId: uomOptions.length > 0 ? uomOptions[0] : null,
              uomOptions,
              isSelected: false
            }

            setStockIn({ ...stockIn, stockInItems: [ ...stockIn.stockInItems, newEntry ] })
          })
        }
        break
    }
  }

  const handleDeleteSelected = () => {
    setStockIn({ ...stockIn, stockInItems: stockIn.stockInItems.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setStockIn({
      ...stockIn,
      stockInItems: stockIn.stockInItems.map(entry => {
        return {
          ...entry,
          isSelected: checked
        }
      })
    })
  }

  const handleStockInItemChange = entry => {
    setStockIn({
      ...stockIn,
      stockInItems: stockIn.stockInItems.map(a => a.index === entry.index ? entry : a)
    })
  }

  const handleDeleteStockInItem = (entry) => {
    setStockIn({
      ...stockIn,
      stockInItems: stockIn.stockInItems.filter(e => e.index !== entry.index)
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setStockIn({
      ...stockIn,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setStockIn({
      ...stockIn,
      [name]: value
    })
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = stockIn.date && stockIn.name
    let hasNull = stockIn.stockInItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockIn.stockInItems.length > 0) {
      let data = {
        ...stockIn,
        date: stockIn.date?.toISOString(),
        stockInItems: stockIn.stockInItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleSubmitStockConfirmation = () => {
    setModalConfirmStock(false)
    setIsSubmitted(true)
    let isValid = stockIn.date && stockIn.name
    let hasNull = stockIn.stockInItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockIn.stockInItems.length > 0) {
      let data = {
        ...stockIn,
        status: 1,
        date: stockIn.date?.toISOString(),
        stockInItems: stockIn.stockInItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }


  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
                {
                  stockIn.id !== NIL ?
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" outline>
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                              onClick={ () => setModalPrintPreview(true) }
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print Preview
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown> : null
                }
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      placeholder="Auto Generate"
                      value={ stockIn.code }
                      readOnly
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Title</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Title"
                      autoComplete="off"
                      className={ isSubmitted && !stockIn.name ? "is-invalid" : "" }
                      value={ stockIn.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !stockIn.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>
              </Col>
              <Col>
                <FormGroup
                    className={
                        "select2-container" + (isSubmitted && !stockIn.date ? " is-invalid" : "")
                    }
                >
                  <Label>Stock In Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="date"
                      selected={ stockIn.date }
                      onChange={ obj => handleOnDateChange('date', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Stock In Date"
                      isClearable
                  />
                </FormGroup>
                { isSubmitted && !stockIn.date && (
                    <div className="invalid-feedback-custom">
                      Stock In Date is required.
                    </div>
                ) }
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <Select
                    name="itemId"
                    value={ null }
                    placeholder={ "Find by Code, Name, ..." }
                    onChange={ handleSelectChange }
                    options={ itemOptions }
                    components={ {
                      SingleValue: customSingleValue
                    } }
                    onInputChange={ handleInputChange }
                    formatOptionLabel={ formatItemOptionLabel }
                    classNamePrefix="select2-selection"
                    isLoading={ loading }
                    isClearable
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline
                          disabled={ !stockIn.stockInItems.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>

              {
                stockIn.stockInItems.map((entry, index) => {
                  return <StockInItemRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockInItemChange }
                      onDelete={ handleDeleteStockInItem }
                  />
                })
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
            {
                [ 0 ].includes(stockIn.status) && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleSubmit }
                        type="submit">
                  Submit
                </Button>
            }
            {
                stockIn.id !== NIL && [ 0 ].includes(stockIn.status) && stockIn.stockInItems.length > 0 && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ () => setModalConfirmStock(true) }
                        type="submit">
                  Confirm Into Stock
                </Button>
            }

            <Button color="secondary"
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>

        <StockInConfirmModal
            title="Confirm item into stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />

        <PrintPreviewModal
            isOpen={ modalPrintPreview }
            stockInId={ stockIn.id }
            toggle={ () => setModalPrintPreview(!modalPrintPreview) }
        />
      </>
  )
}

StockInForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default StockInForm